import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	/* {
		path: '/',
		redirect: '/index',
	}, */
	{
		path: '/',
		name: 'index',
		component: () => import("../pages/index/indexPage.vue"),
		meta: {
			activeMenu: '/'
		}
	},
	{
		path: '/train/:cid?',
		name: 'train',
		component: () => import("../pages/train/indexPage.vue"),
		meta: {
			activeMenu: '/train'
		}
	},
	{
		path: '/train/detail/:id',
		name: 'trainDetail',
		component: () => import("../pages/train/detailPage.vue"),
		meta: {
			activeMenu: '/train'
		}
	},
	{
		path: '/pay/detail',
		name: 'payDetail',
		component: () => import("../pages/pay/detailPage.vue"),
		meta: {
			activeMenu: '/train'
		}
	},
	{
		path: '/news/detail/:id',
		name: 'newsDetail',
		component: () => import("../pages/news/detailPage.vue"),
		meta: {
			activeMenu: '/news'
		}
	},
	{
		path: '/news',
		name: 'news',
		component: () => import("../pages/news/indexPage.vue"),
		meta: {
			activeMenu: '/news'
		}
	},
	{
		path: '/about',
		name: 'about',
		component: () => import("../pages/about/indexPage.vue"),
		meta: {
			activeMenu: '/about'
		}
	},
	{
		path: '/querycer',
		name: 'querycer',
		component: () => import("../pages/querycer/indexPage.vue"),
		meta: {
			activeMenu: '/querycer'
		}
	},
	{
		path: '/user/index',
		name: 'userIndex',
		component: () => import("../pages/user/mainPage.vue"),
		children: [
			{
				path: '/user/train',
				name: 'userTrain',
				component: () => import("../pages/user/train/trainPage.vue"),
				meta: {
					activeMenu: '/user/index'
				}
			},
			{
				path: '/user/resit',
				name: 'userTrain',
				component: () => import("../pages/user/train/resitPage.vue"),
				meta: {
					activeMenu: '/user/resit'
				}
			},
			{
				path: '/user/order',
				name: 'userOrder',
				component: () => import("../pages/user/order/indexPage.vue"),
				meta: {
					activeMenu: '/user/order'
				},
			},
			{
				path: '/order/invoice',
				name: 'orderInvoice',
				component: () => import("../pages/user/order/invoicePage.vue"),
				meta: {
					activeMenu: '/order/invoice'
				},
			},
			{
				path: '/order/invoiceDetail',
				name: 'orderInvoiceDetail',
				component: () => import("../pages/user/order/invoiceDetailPage.vue"),
				meta: {
					activeMenu: '/order/invoice'
				},
			},
			{
				path: '/info/index',
				name: 'infoIndex',
				component: () => import("../pages/user/info/indexPage.vue"),
				meta: {
					activeMenu: '/info/index'
				},
			},
			{
				path: '/invoice/index',
				name: 'invoiceIndex',
				component: () => import("../pages/user/invoice/indexPage.vue"),
				meta: {
					activeMenu: '/invoice/index'
				},
			},
			{
				path: '/invoice/add',
				name: 'invoiceAdd',
				component: () => import("../pages/user/invoice/addPage.vue"),
				meta: {
					activeMenu: '/invoice/index'
				},
			},
			{
				path: '/invoice/edit',
				name: 'invoiceEdit',
				component: () => import("../pages/user/invoice/editPage.vue"),
				meta: {
					activeMenu: '/invoice/index'
				},
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: () => import("../pages/auth/loginPage.vue"),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import("../pages/auth/registerPage.vue"),
	},
	/* {
		path: '/forgotPassword',
		name: 'forgotPassword',
		component: () => import("../pages/auth/forgotPassword.vue"),
	},
	{
		path: '/user/index',
		name: 'userIndex',
		component: () => import("../pages/layout/index.vue"),
		children: [
			{
				path: '/user/accountinfo',
				name: 'userAccountinfo',
				component: () => import("../pages/user/accountinfo/index.vue"),
				meta: {
					activeMenu: '/user/accountinfo'
				}
			},
			{
				path: '/user/messageuser/index',
				name: 'userMessageuser',
				component: () => import("../pages/user/messageuser/index.vue"),
			},
			{
				path: '/user/what/index',
				name: 'userWhat',
				component: () => import("../pages/user/what/index.vue"),
			},
			{
				path: '/user/curriculum/index',
				name: 'userCurriculum',
				component: () => import("../pages/user/curriculum/index.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				},
			},
			{
				path: '/company/applicant',
				name: 'companyApplicant',
				component: () => import("../pages/company/applicant.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/company/order/detail',
				name: 'companyOrderDetail',
				component: () => import("../pages/company/order/detail.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/user/curriculum/detail',
				name: 'userCurriculumDetail',
				component: () => import("../pages/user/curriculum/detail.vue"),
				meta: {
					activeMenu: '/user/curriculum/index'
				}
			},
			{
				path: '/user/order/index',
				name: 'userOrder',
				component: () => import("../pages/user/order/index.vue"),
				meta: {
					activeMenu: '/user/order/index'
				}
			},
			{
				path: '/user/order/detail',
				name: 'userOrderDetail',
				component: () => import("../pages/user/order/detail.vue"),
				meta: {
					activeMenu: '/user/order/index'
				}
			},
			{
				path: '/user/invoice/add',
				name: 'userInvoiceAdd',
				component: () => import("../pages/user/invoice/add.vue"),
			},
			{
				path: '/user/invoice/info',
				name: 'userInvoiceInfo',
				component: () => import("../pages/user/invoice/info.vue"),
			},
			{
				path: '/user/invoice/details',
				name: 'userInvoiceDetail',
				component: () => import("../pages/user/invoice/details.vue"),
			},
			{
				path: '/user/training/list',
				name: 'userTrainingList',
				component: () => import("../pages/user/training/list.vue"),
			},
			{
				path: '/user/training/records',
				name: 'userTrainingRecords',
				component: () => import("../pages/user/training/records.vue"),
			},
			{
				path: '/user/training/detail',
				name: 'userTrainingRecords',
				component: () => import("../pages/user/training/detail.vue"),
			},
			{
				path: '/user/order/paymentSucceeded',
				name: 'paymentSucceeded',
				component: () => import("../pages/user/order/paymentSucceeded.vue"),
			},
			{
				path: '/user/index/info',
				name: 'userIndexInfo',
				component: () => import("../pages/user/index/info.vue"),
			},
			{
				path: '/user/index/index',
				name: 'userIndexIndex',
				component: () => import("../pages/user/index/index.vue"),
			},
			{
				path: '/user/exam/list',
				name: 'makeExam',
				component: () => import("../pages/user/exam/list.vue"),
			},
		],
	}, */
    


];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
	//console.log(to);
	//console.log(from);
	//console.log(next);
	//console.log(localStorage.getItem('isLogin'));
	// to: Route: 即将要进入的目标 路由对象
	// from: Route: 当前导航正要离开的路由
	// next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

	//不需要验证登录的路由
	
	let isLogin = localStorage.getItem('isLogin');  // 登录状态
	const nextRoute = ['login'];
	// 未登录状态；当路由到nextRoute指定页时，跳转至login
	if (nextRoute.indexOf(to.name) >= 0) {
		if (isLogin) {
			router.push({ name: 'index' })
		}
	}

	// 已登录状态；当路由到login时，跳转至index
	if (to.name === 'login') {
		if (isLogin) {
			router.replace('/')
		}
	}

	next();
});

export default router;
