import Vue from 'vue'
import App from './App.vue'
//引入路由组件
import router from './router';
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//element-ui的css
import QRCode from "qrcode";
//全局引入请求方法
import request from '../utils/request'
import myMessage from '../utils/message';

//二、安装使用
//安装使用插件,插件通常用来为 Vue 添加全局功能
Vue.use(ElementUI);
Vue.use(QRCode);

//三、直接挂载注册
//如果不想通过编写组件然后Vue.use的方式引入全局方法，也可以直接在main.js通过Vue.prototype注册全局方法/变量/组件
//挂载到vue原型上
Vue.prototype.$request = request;
Vue.prototype.$myMessage = myMessage;
Vue.prototype.$QRCode = QRCode;

const bus = new Vue();
Vue.prototype.$bus = bus;

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
