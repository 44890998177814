import intefUrl from "./interface"
import $ from './http'
import {
  setToken
} from './http'
/* *接口数据的统一调用* */
export default {
  /***    获取通用内容    ***/
  // 登录
  login(params) {
    return $.post(intefUrl.login, params);
  },
  //忘记密码
  forgetPwd(params) {
    return $.post(intefUrl.forgetPwd, params);
  },
  //未登录前发送手机验证码
  sendCodeBefor(params) {
    return $.post(intefUrl.sendCodeBefor, params);
  },
  getUserInfo(params) {
    return $.post(intefUrl.getUserInfo, params);
  },
  //获取广告轮播图
  getFlash(params) {
    return $.post(intefUrl.getFlash, params);
  },
  //获取培训对象
  getTrainObject(params) {
    return $.post(intefUrl.getTrainObject, params);
  },
  //获取课程类型
  getCurriculumCate(params) {
    return $.post(intefUrl.getCurriculumCate, params);
  },
  //获取培训方式
  getTrainType(params) {
    return $.post(intefUrl.getTrainType, params);
  },
  //获取课程列表
  getCurriculumLists(params) {
    return $.post(intefUrl.getCurriculumLists, params);
  },
  //课程报名
  getCurriculumBaoming(params) {
    return $.post(intefUrl.getCurriculumBaoming, params);
  },
  //课程详情
  getCurriculumDetail(params) {
    return $.post(intefUrl.getCurriculumDetail, params);
  },
  //课程订单详情
  getOrderDetail(params) {
    return $.post(intefUrl.getOrderDetail, params);
  },
  //未登录 获取手机验证码
  sendCodeAfter(params) {
    return $.post(intefUrl.sendCodeAfter, params);
  },
  //学历分类
  getEducationCate(params) {
    return $.post(intefUrl.getEducationCate, params);
  },
  //上传接口
  uploadImages(params) {
    return $.post(intefUrl.uploadImages, params);
  },
  //检测注册基本信息
  checkRegisterBase(params) {
    return $.post(intefUrl.checkRegisterBase, params);
  },
  //员工去注册
  toRegister(params) {
    return $.post(intefUrl.toRegister, params);
  },
  //获取用户系统消息列表
  getMessageUser(params) {
    return $.post(intefUrl.getMessageUser, params);
  },
  //消息设置为已读
  setMessageUserRead(params) {
    return $.post(intefUrl.setMessageUserRead, params);
  },
  //消息全部清空
  delAllMessageUser(params) {
    return $.post(intefUrl.delAllMessageUser, params);
  },

  //订单列表
  getOrder(params) {
    return $.post(intefUrl.getOrder, params);
  },
  //取消报名/订单
  cencalBaoming(params) {
    return $.post(intefUrl.cencalBaoming, params);
  },
  //删除报名/订单
  delBaoming(params) {
    return $.post(intefUrl.delBaoming, params);
  },
  //本地视频
  getVideoLists(params) {
    return $.post(intefUrl.getVideoLists, params);
  },
  //新闻分类
  getNewsCate(params) {
    return $.post(intefUrl.getNewsCate, params);
  },
  //新闻列表
  getNewsLists(params) {
    return $.post(intefUrl.getNewsLists, params);
  },
  //新闻详情
  getNewsDetail(params) {
    return $.post(intefUrl.getNewsDetail, params);
  },

  //获取开票类型
  // getKaiPiaoType(params) {
  //   return $.post(intefUrl.getKaiPiaoType, params);
  // },
  //获取用户开票信息
  getUserInvoiceList(params) {
    return $.post(intefUrl.getUserInvoiceList, params);
  },
  //添加发票
  addUserInvoice(params) {
    return $.post(intefUrl.addUserInvoice, params);
  },
  //发票详情
  getInvoicedetail(params) {
    return $.post(intefUrl.getInvoicedetail, params);
  },
  //编辑发票
  editUserInvoice(params) {
    return $.post(intefUrl.editUserInvoice, params);
  },
  //删除发票
  delUserInvoice(params) {
    return $.post(intefUrl.delUserInvoice, params);
  },
  // 获取微信支付地址
  getScannedCodePay(params) {
    return $.post(intefUrl.getScannedCodePay, params);
  },
  // 获取全部课程
  getTrainLists(params) {
    return $.post(intefUrl.getTrainLists, params);
  },
  // 获取发票列表
  getOrderinvoiceLists(params) {
    return $.post(intefUrl.getOrderinvoiceLists, params);
  },
  // 获取发票详情
  getOrderinvoiceDetail(params) {
    return $.post(intefUrl.getOrderinvoiceDetail, params);
  },
  // 定时循环确认支付
  getPayIssuccess(params) {
    return $.post(intefUrl.getPayIssuccess, params);
  },
  // 进入课程学习
  getTrainTostudy(params) {
    return $.post(intefUrl.getTrainTostudy, params);
  },
  // 配置信息
  getWebsiteInfo(params) {
    return $.post(intefUrl.getWebsiteInfo, params);
  },
  // 发证信息
  getCertificateList(params) {
    return $.post(intefUrl.getCertificateList, params);
  },
  // 修改个人信息
  getUpdateInfo(params) {
    return $.post(intefUrl.getUpdateInfo, params);
  },
  // 补考记录
  getExamLogList(params) {
    return $.post(intefUrl.getExamLogList, params);
  },
  // 常见问题列表
  getWhatList(params) {
    return $.post(intefUrl.getWhatList, params);
  },
  // 常见问题详情
  getWhatInfo(params) {
    return $.post(intefUrl.getWhatInfo, params);
  },
  // 企业类型分类
  getEnterprisetype(params) {
    return $.post(intefUrl.getEnterprisetype, params);
  },

}
