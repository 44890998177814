<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
	html {
		font-size: 16px;
	}

	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', 'Microsoft Yahei', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	* {
		box-sizing: border-box;
		flex-shrink: 0;
	}
	.box{
		width: 1030px;
		margin: 0 auto;
	}
	a{
		font-size: 14px;
		color: #000;
		text-decoration: none;
	}
	ul,ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	a img {
		border-style: none;
	}
	button,
	input[type="button"],
	input[type="submit"] {
		background-color: transparent;
		border: none;
		cursor: pointer;
		padding: 0;
		outline: none;
	}
	body,
	h1,
	h2,
	h3,
	h4,
	p,
	figure,
	blockquote,
	dl,
	dd {
		margin: 0;
	}
	.price{color: #FF5F5A!important;}
</style>
