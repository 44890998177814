// const baseUrl = 'https://zh-api.hnzhonghe.cn/api/v1/';  //和代理相对应的请求地址
const baseUrl = 'https://demo-api.coder-space.com/api/v1/'; //测试和代理相对应的请求地址
/* *api接口的统一管理* */
export default {
  //登录
  login: baseUrl + 'auth/login',
  //忘记密码
  forgetPwd: baseUrl + 'auth/resetpwd',
  //未登录前发送手机验证码
  sendCodeBefor: baseUrl + 'auth/sendCode',
  //获取用户信息
  getUserInfo: baseUrl + 'user/info',
  //修改用户信息
  getUpdateInfo: baseUrl + 'user/update',
  //获取广告轮播图
  getFlash: baseUrl + 'ad/flash',
  //获取培训对象
  getTrainObject: baseUrl + 'cate/trainobject',
  //获取课程类型
  getCurriculumCate: baseUrl + 'cate/curriculumcate',
  //获取培训方式
  getTrainType: baseUrl + 'cate/traintype',
  //获取课程列表
  getCurriculumLists: baseUrl + 'curriculum/lists',
  //课程详情
  getCurriculumDetail: baseUrl + 'curriculum/detail',
  //课程报名
  getCurriculumBaoming: baseUrl + 'order/create',
  //课程订单详情
  getOrderDetail: baseUrl + 'order/detail',
  //未登录 获取手机验证码
  sendCodeAfter: baseUrl + 'auth/sendCode',
  //学历分类
  getEducationCate: baseUrl + 'cate/educationCate',
  //上传接口
  uploadImages: baseUrl + 'uploadfile/uploadImages',
  //检测注册的基本信息
  checkRegisterBase: baseUrl + 'auth/checkBase',
  //注册
  toRegister: baseUrl + 'auth/register',
  //获取用户系统消息列表
  getMessageUser: baseUrl + 'messageuser/lists',
  //消息设置为已读
  setMessageUserRead: baseUrl + 'messageuser/setRead',
  //消息全部清空
  delAllMessageUser: baseUrl + 'messageuser/del',
  //订单列表
  getOrder: baseUrl + 'order/index',
  //取消报名/订单
  cencalBaoming: baseUrl + 'order/cancel',
  //删除报名/订单
  delBaoming: baseUrl + 'order/del',
  //本地视频
  getVideoLists: baseUrl + 'video/lists',
  //新闻分类
  getNewsCate: baseUrl + 'cate/newscate',
  //新闻列表
  getNewsLists: baseUrl + 'news/lists',
  //新闻详情
  getNewsDetail: baseUrl + 'news/detail',

  //获取开票类型
  // getKaiPiaoType: baseUrl + 'cate/invoice',
  //获取用户开票信息
  getUserInvoiceList: baseUrl + 'user/invoicelist',
  //添加发票
  addUserInvoice: baseUrl + 'user/addinvoice',
  //开票详情
  getInvoicedetail: baseUrl + 'user/invoicedetail',
  //编辑发票
  editUserInvoice: baseUrl + 'user/editinvoice',
  //删除发票
  delUserInvoice: baseUrl + 'user/delinvoice',
  // 获取用户微信支付地址
  getScannedCodePay: baseUrl + 'pay/scannedCodePay',
  // 获取全部课程
  getTrainLists: baseUrl + 'train/lists',
  // 发票列表
  getOrderinvoiceLists: baseUrl + 'orderinvoice/index',
  // 发票详情
  getOrderinvoiceDetail: baseUrl + 'orderinvoice/detail',
  // 定时循环确认支付
  getPayIssuccess: baseUrl + 'pay/issuccess',
  // 课程修改课程订单状态
  // getFinishStudy: baseUrl + 'third/curriculum/finishStudy',
  //进入课程学习
  getTrainTostudy: baseUrl + 'train/tostudy',
  //配置信息
  getWebsiteInfo: baseUrl + 'website/getInfo',
  //发证记录
  getCertificateList: baseUrl + 'certificate/lists',
  //补考记录
  getExamLogList: baseUrl + 'train/examLog',
  //常见问题列表
  getWhatList: baseUrl + 'commonproblem/lists',
  //常见问题详情
  getWhatInfo: baseUrl + 'commonproblem/detail',
  // ***********
  // ============企业=====================================================
  // ***********
  //企业类型分类
  getEnterprisetype: baseUrl + 'cate/enterprisetype',
  // 企业员工列表
  getUserLists: baseUrl + 'qiye/users',
  // 企业员工密码修改
  getSavePwd: baseUrl + 'qiye/savePwd',
  // 企业课程管理列表
  getCourse: baseUrl + 'qiye/course',
  // 企业课程报名人数列表/未缴费人数列表/已缴费人数列表
  getBmUser: baseUrl + 'qiye/bmUser',
  // 取消报名/批量取消报名
  getCancelBm: baseUrl + 'qiye/cancelBm',
  // 批量缴费
  getCreateOrder: baseUrl + 'qiye/createOrder',
  // 企业订单详情
  geQyOrderDetail: baseUrl + 'qiye/orderDetail',
  // 企业扫码支付地址
  getQyScannedCodePay: baseUrl + 'qiye/qyScannedCodePay',
  // 发证记录
  getCertificate: baseUrl + 'qiye/certificate',
}
